import  React ,{ createContext, useContext, useCallback, useState } from 'react'
import { uuid } from 'uuidv4'
import ToastContainer from '../components/ToastContainer'


export interface ToastMessage {
    id: string
    type?: 'success' | 'error' | 'info'
    title: string
    description?: string
}

interface ToastContextData { 
    addToast(message: Omit<ToastMessage, 'id'>): void
    removeToast(id: string): void
}


const ToastContext = createContext<ToastContextData>({} as ToastContextData)

const ToastProvider: React.FC = ({ children }) => { //metodos

    const [messages, setMessages] = useState<ToastMessage[]>([]) //array pois pode ter mais de um toast

    const addToast = useCallback(({ type, title, description }: Omit<ToastMessage, 'id'> /*todas as propriedades menos o id */) => {
        const id = uuid()

        const toast = {
            id,
            type,
            title,
            description
        }

        setMessages(state =>  [...state, toast])
    },[])

    const removeToast = useCallback((id: string) => {
        setMessages((state) => state.filter((message) => message.id !== id))
    },[])

    return (
        <ToastContext.Provider value={{ addToast, removeToast }} >
         {children}
         <ToastContainer messages={messages} />
        </ToastContext.Provider>
    )
}

function useToast() { //validação
    const context = useContext(ToastContext)

    if (!context ) {
        throw new Error('useToast must be used within a ToastProvider')
    }

    return context

}

export { ToastProvider, useToast }